<template>
  <div>
    <b-card>
      <div class="pl-4 pr-4">
        <div v-for="notification in notificationList" :key="notification.id">
          <div class="pt-3">
            <b-row class="d-flex">
              <b-avatar
                badge
                badge-left
                badge-top
                badge-offset="-10px"
                badge-variant="danger"
                class="mr-3"
                src="https://placekitten.com/300/300"
              ></b-avatar>
              <div class="ml-1">
                <div class="info">
                  <span class="font-weight-bold">{{ notification.employeeName }}</span>
                  add new project
                  <span class="font-weight-bold">{{
                    notification.projectName
                  }}</span>
                  <br>
                  <span class="message-notification">{{
                    notification.message
                    }}</span>
                </div>
              </div>
            </b-row>
            <b-row>
              <b-col class="ml-5">{{ moment(notification.createdDate).format('YYYY-MM-DD HH:mm:ss') }}</b-col>
            </b-row>
            <hr class="hr" />
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  data() {
    return {
      notificationList: []
    }
  },
  created() {
    try {
      this.$notificationHub.receiveMessage()
      this.$notificationHub.$on('receive-all', this.onLoadNotification)
      this.$notificationHub.$on('receive-push', this.onNotification)
    } catch (error) {
      console.log('notification : ' + error)
    }
  },
  beforeDestroy() {
    this.$notificationHub.$off('receive-all', this.onLoadNotification)
    this.$notificationHub.$off('receive-push', this.onNotification)
  },
  methods: {
    moment,
    openNotificationsPage() {
      this.$router.push({ name: 'Notifications' })
    },
    onNotification(data) {
      this.notificationList.pop()
      this.notificationList.unshift(data)
    },
    onLoadNotification(data) {
      this.notificationList = data
    }
  }
}
</script>

<style scoped>
.info {
  margin-top: -5px;
  text-align: start;
}
.time {
  width: auto;
  text-align: center;
}
.message-notification {
  font-size: 12px;
}
</style>
